
.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:0rem;
    margin-left:2rem;
    gap: 12.5rem;
}

.heading-font {
    line-height: 38px;
    display: block;
    align-items: center;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #0367B4;
    margin-left:9rem;
    margin-top:0rem;
    overflow-y: auto;

}

.heading-font span {
    font-size: 27px;
}

.subheading-font {
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.body {
    min-height: 100vh;
    margin-bottom: -40px;
  }

  .horizontal-kela{
    display: flex;
    flex-direction: row;
    margin-left:9rem;

  }



  
  