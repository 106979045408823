html {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

body {
  overflow: auto;
  margin: 0; 
  padding: 0px;
  font-variant: normal !important; -webkit-font-variant: normal !important; font-variant-ligatures: none !important; -webkit-font-variant-ligatures: none !important;
}