.card-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #000000;
}

.kelacard {

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-grow:0;
    align-items: left;
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #000000;
    background-color: #F2C76D;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    padding: 32px 24px;
    margin-bottom: 4vh;
    width: 25rem;
    margin-right: 6rem;
    height: 13rem;

}

.level {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.risks {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    padding-top:1rem;
    display: block;
    align-items: center;

    color: #000000;
}

emph {
    font-weight: 700;
    font-size:20px;
}