
.background {
    top: 0px;
    left: 0px;
    background: #E4E4E4;
    position: absolute;
    height:100%;
    width:100%;
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    
}

.main-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 503px;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    background: #0367B4;
}

.top-margin {
    display:flex;
    flex-direction: row;
    height: 106px;
    background: #0367B4;
    padding: 0px;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top:4vh;
    padding-bottom: 3vh;
}



.main-container img {
    align-items: center;
}


.dropdown-welcome {
    background: white;
    text-indent: 2rem;
    display: flex;
    flex-direction: column;
    
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    display: block;
    color: #333333;
    order: 0;
    margin-bottom: .8rem;
    margin-top: .8rem;
    
}

h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #505050;
    margin-top: 0px;
    order: 1;
    margin-bottom: 2rem;
}

.dropdown {
    background: white;
    order:2;
    text-indent: 2rem;

}

select {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2rem;
    width: 85%;
    border-color: #CED4DA;
    border-radius: 4px;
    height: 2rem;
}

.submit input {
    background: #0367B4;
    width: 65%;
    border: none;
    font-family: 'Roboto', sans-serif;
    line-height: 30px;    
}

.submit {
    display:flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    padding-top: 2rem;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}