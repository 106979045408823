.NavbarItems {
    padding: 16px 48px;
    background: #0367B4;
    height: 94px;
    display:flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16.9786px;
    line-height: 25px;

}

.navbar-logo {
    cursor: pointer;
    
    flex: none;
    order: 0;
    
    flex-grow: 0;

}

.welcome-text{
    color:white;
    text-align: center;
    align-items: center;
    line-height: 19px;
    font-size: 14px;
    padding: 16px 48px;
    display:flex;
    margin-left: 12rem;
}

.nav-menu{
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    padding: 16px 48px;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;

}



.nav-links {
    font-weight: 500;
    font-size: 16.9786px;
    line-height: 25px;
    color:white;
    text-decoration: none;
}

.nav-links:visited {
    color:white;
    text-decoration: none;
}

.nav-links:hover {
    text-decoration: underline;
}

.image-format {
    align-items: center;
    justify-content: center;
    display:flex;
    
}

.nav-menu li{
    margin-right: 1.4vw;
}